<!--
  -  TTTech nerve-management-system
  -  Copyright(c) 2022. TTTech Industrial Automation AG.
  -
  -  ALL RIGHTS RESERVED.
  -
  -  Usage of this software, including source code, netlists, documentation,
  -  is subject to restrictions and conditions of the applicable license
  -  agreement with TTTech Industrial Automation AG or its affiliates.
  -
  -  All trademarks used are the property of their respective owners.
  -
  -  TTTech Industrial Automation AG and its affiliates do not assume any liability
  -  arising out of the application or use of any product described or shown
  -  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
  -  make changes, at any time, in order to improve reliability, function or
  -  design.
  -
  -  Contact Information:
  -  support@tttech-industrial.com
  -
  -  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
  -
  -->

<template>
  <v-main>
    <div id="core-view">
      <v-fade-transition mode="out-in">
        <router-view :key="$route.path"></router-view>
      </v-fade-transition>
    </div>
    <api-toast />
    <api-message-box />
    <api-loading-bar />
    <confirm-dialog />
    <api-permission-confirmation-dialog />
    <apply-workload-configuration-dialog />
    <notification-dialog />
    <error-info-dialog />
    <info />
    <progress-bar-dialog />
    <deploy-info-dialog />
    <api-loading-circular />
  </v-main>
</template>

<script>
import ApiToast from '@/components/api-dialogs/ApiToast.vue';
import ApiMessageBox from '@/components/api-dialogs/ApiMessageBox.vue';
import ApiLoadingBar from '@/components/api-dialogs/ApiLoadingBar.vue';
import ConfirmDialog from '@/components/api-dialogs/Confirm.vue';
import ApiPermissionConfirmationDialog from '@/components/roles/ApiPermissionConfirmDialog.vue';
import ApplyWorkloadConfigurationDialog from '@/components/nodes/deployed-workload-control/ApplyWorkloadConfigurationDialog.vue';
import NotificationDialog from '@/components/api-dialogs/NotificationDialog.vue';
import ErrorInfoDialog from '@/components/api-dialogs/ErrorInfoDialog.vue';
import Info from '@/components/api-dialogs/Info.vue';
import DeployInfoDialog from '@/components/api-dialogs/DeployInfoDialog.vue';
import ProgressBarDialog from '@/components/api-dialogs/ProgressBarDialog.vue';
import ApiLoadingCircular from '@/components/api-dialogs/ApiLoadingCircular.vue';
import Logger from '@/utils/logger';

export default {
  components: {
    ApiToast,
    ApiMessageBox,
    ApiLoadingBar,
    ConfirmDialog,
    ApiPermissionConfirmationDialog,
    ApplyWorkloadConfigurationDialog,
    NotificationDialog,
    ErrorInfoDialog,
    Info,
    ProgressBarDialog,
    DeployInfoDialog,
    ApiLoadingCircular,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn']();
    },
  },
  async mounted() {
    try {
      await this.$store.dispatch('ldap/get_active_ldap');
    } catch (e) {
      Logger.error(e);
    }
  },
  metaInfo() {
    return {
      title: this.$t('app.title'),
    };
  },
};
</script>
